<script setup lang="ts">
import type { CmsElementBioCircle } from "~/composables/useCustom";
import SharedHeadline from "~/components/shared/SharedHeadline.vue";
import SharedTopline from "~/components/shared/SharedTopline.vue";

const props = defineProps<{
  content: CmsElementBioCircle;
}>();

const {
  headlineText,
  toplineText,
  headlineType,
  toplineType,
  Boxes,
  boxColsPerRowDesktop,
  boxColsPerRowMobile,
  boxColsPerRowTablet,
} = props.content?.data;

</script>
<template>
  <section class="relative">
    <div class="mb-10 lg:mb-[3.75rem]">
      <div class="container px-0 xl:px-5">
        <SharedTopline :headline="toplineText" :headlineType="toplineType"></SharedTopline>
        <SharedHeadline :headline="headlineText" :headlineType="headlineType"></SharedHeadline>
      </div>
    </div>
    <div class="prose-p:text-sm prose-p:text-skin-primary">
      <div class="container max-w-[90rem] px-0 xl:px-10">
        <div :class="'grid grid-cols-1 xs:grid-cols-'+boxColsPerRowMobile+' md:grid-cols-'+boxColsPerRowTablet+' lg:grid-cols-'+boxColsPerRowDesktop+' gap-5'">
          <div v-for="box in Boxes" :key="box" class="relative">
            <div class="bg-skin-white p-6  text-skin-primary text-center rounded-[0.625rem] min-h-[18.6rem] flex items-center">
              <div class="w-full">
                <div class="flex items-center justify-center mb-2">
                  <div class="rounded-10 flex overflow-hidden" v-if="box.boxImage && box.boxImage.media && box.boxImageType !== 'icon'">
                    <NuxtImg loading="lazy" format="webp" :src="box.boxImage.media.url" width="400" />
                  </div>
                  <NuxtImg v-if="box.boxImage && box.boxImage.media && box.boxImageType === 'icon'" loading="lazy" format="webp" :src="box.boxImage.media.url" class="h-6"  />
                </div>
                <h4 class="font-bold font-base mb-5" v-html="box.boxTitle"></h4>
                <div class="prose-p:*" v-html="box.boxText"></div>
                <div >
                  <SharedButton v-if="box.boxLink" :button="box.boxLink" class="btn-outline btn-sm mt-6" btn-type="btn-outline btn-sm"></SharedButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <img src="/images/layer2.svg" alt="" class="absolute right-4 -top-16 hidden lg:block">
  </section>

</template>
